export const RequestError = function (message, status) {
  this.message = message
  this.status = status
}
RequestError.prototype = Error.prototype

const checkStatusResponse = (response) => {
  const status = response.status
  const successStatus = status >= 200 && status < 300

  if (successStatus) {
    return response
  }

  throw new RequestError('response error', status)
}

const isGet = (method) => {
  return method.toLowerCase() === 'get'
}

const parseResponse = (response) => (
  response.json().catch(() => true)
)

export const request = (url, method, data, headers, options, reloadUnauthorized = false) => {
  return fetch(url, {
    method,
    headers,
    body: isGet(method) ? undefined : JSON.stringify(data),
    ...options
  })
    .then(checkStatusResponse)
    .then(parseResponse)
    .catch((error) => {
      if (reloadUnauthorized && (error.status === 401 || error.message === 'TypeMismatchError')) {
        window.location.reload()
      }

      error.url = url
      return { error }
    })
}

const fixHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const constructHeaders = (addedHeaders) => {
  return {
    ...fixHeaders,
    ...addedHeaders
  }
}

const constructHeadersApiGateway = (addedHeaders, headers) => {
  return {
    ...addedHeaders,
    ...headers
  }
}

export const fetchCtiClient = (headers, {
  data,
  endpoint = '/cti/message',
  method = 'POST'
}) => request(endpoint, method, data, constructHeaders(headers), { credentials: 'include' }, true)


export const fetchAccessToken = ({
  endpoint = '/auth/token',
  method = 'GET'
}) => {
  return request(endpoint, method, undefined, {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }, { credentials: 'include' }, true)
}

export const fetchApiGateway = (addedHeader, {
  data,
  apiGatewayUrl,
  endpoint,
  method = 'POST',
  headers
}) => {
  const url = `${apiGatewayUrl}${endpoint}`

  return request(url, method, data, constructHeadersApiGateway(addedHeader, headers))
}

export const sendToCtiClient = ({
  data,
  endpoint = '/cti/message',
  method = 'POST'
}) => request(endpoint, method, data, {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}, { credentials: 'include' }, true)
