// pusher events
// - incoming
export const OPEN_CONTACT_PUSHER = 'open-contact'
export const OPEN_PROFILE = 'open-profile'
export const CALLBAR_VISIBILITY = 'callbar-visibility'
export const REQUEST_INTERACTION_EXTERNAL_ID_PUSHER = 'request-interaction-external-id'
export const OPEN_TICKET_PUSHER = 'open-ticket'
export const HVS_CALL_END_PUSHER = 'hvs-call-end'
export const OPEN_RESOURCE_PUSHER = 'open-resource'
export const LOGIN_SUCCESS_TIMESPAMP = 'transport-handshake-request'
export const AGENT_STATUS_CHANGED_PUSHER = 'agent-status-changed'
export const FETCH_RELATE_OPTIONS_PUSHER = 'fetch-relate-options'
export const SEND_DATA_PUSHER = 'send-data'
export const REQUEST_RELATED_OPTIONS_BY_CHANNEL_PUSHER = 'request-related-options-by-channel'
// - outgoing
export const RESPONSE_RELATING_OPTIONS = 'response-relating-options'
export const INTEGRATION_STATUS_CHANGED_PUSHER = 'integration-status-changed'
export const CLICK_TO_CALL_PUSHER = 'click-to-call'
export const RESPONSE_INTERACTION_EXTERNAL_ID_PUSHER = 'response-interaction-external-id'
export const RESPONSE_RELATED_OPTIONS_BY_CHANNEL_PUSHER = 'response-related-options-by-channel'

// rtm events
export const RTM_SCOPE = 'user'
export const RTM_TALKDESK_TO_CTI_TOPIC = 'talkdesk_to_cti'
export const RTM_CTI_TO_TALKDESK_TOPIC = 'cti_to_talkdesk'
// - incoming
export const OPEN_CONTACT = 'open_contact'
export const REQUEST_INTERACTION_EXTERNAL_ID = 'request_interaction_external_id'
export const OPEN_TICKET = 'open_ticket'
export const HVS_CALL_END = 'hvs_call_end'
export const OPEN_RESOURCE = 'open_resource'
export const AGENT_STATUS_CHANGED = 'agent_status_changed'
export const FETCH_RELATE_OPTIONS = 'fetch_relate_options'
export const SEND_DATA = 'send_data'
export const CONNECT = 'connect'
export const DISCONNECT = 'disconnect'
export const PRESENCE_CHANNEL_OCCUPIED = 'presence_channel_occupied'
export const PRESENCE_CHANNEL_VACATED = 'presence_channel_vacated'
export const REQUEST_RELATED_OPTIONS_BY_CHANNEL = 'request_related_options_by_channel'
// - outgoing
export const CLICK_TO_CALL = 'click_to_call'
export const RESPONSE_INTERACTION_EXTERNAL_ID = 'response_interaction_external_id'
export const RESPONSE_RELATE_OPTIONS = 'response-relate-options'
export const SEND_METADATA = 'send_metadata'
export const INTEGRATION_STATUS_CHANGED = 'integration_status_changed'
