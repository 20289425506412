import { getUserIntegrationExternalId, getUserDefaultIntegration } from '../service/requests'
import {
  NO_EXTERNAL_ID,
  DIFFERENT_EXTERNAL_IDS,
  FAILED_TALKDESK_EXTERNAL_ID_FETCH,
  MISMATCHED_INTEGRATION_ERROR
} from '../cti-app/components/constants'
import { addQueryParam } from '../helpers/query.string'
import errorReporter from '../config/error-reporter'
import { CTI_CLIENT_CROSS_AGENT_PROTECTION, CTI_CLIENT_SINGLE_SIGN_OUT, CTI_RTM_OVER_PUSHER } from '../featureflags/whitelist'
import MismatchedIntegrationError from '../cti-app/errors/cti-errors'

export const PLATFORM_ELECTRON = 'electron'
export const PLATFORM_CHROME = 'chrome'

class Adaptor {
  constructor (name, getMessageProvider, activeFeatures = [], sessionInfo = {}, hooks = {}, platform = PLATFORM_ELECTRON) {
    if (!name) throw new Error('Adaptor name is required')
    this.name = name
    this.platform = platform
    this.hooks = hooks
    this.getMessageProvider = getMessageProvider
    this.activeFeatures = activeFeatures
    this.sessionInfo = sessionInfo
    this.start()
  }

  start () {
    this.importVendor().then(vendor => {
      this.setSoftphoneHeight = (height) => this.applyHeight(height, vendor)
      this.showPopover = (height) => this.showCTIPopover(vendor, height)
      this.hidePopover = () => this.hideCTIPopover(vendor)
      this.hooks.onBeforeVendorReady && this.hooks.onBeforeVendorReady(this)

      if (this.getMessageProvider) {
        this.onVendorReady(vendor)
        const integrationName = this.name
        Promise.all([
          this.getVendorCapabilities(vendor),
          this.getExternalId(vendor).catch(() => { throw new Error(NO_EXTERNAL_ID) }),
          this.isFeatureActive(CTI_CLIENT_CROSS_AGENT_PROTECTION)
            ? getUserIntegrationExternalId(integrationName).catch(() => { throw new Error(FAILED_TALKDESK_EXTERNAL_ID_FETCH) })
            : Promise.resolve(undefined),
          this.isFeatureActive(CTI_RTM_OVER_PUSHER)
            ? getUserDefaultIntegration()
            : Promise.resolve(undefined),
        ])
          .then(([vendorCapabilities, vendorExternalId, talkdeskUserExternalId, userDefaultIntegration]) => {
            if (talkdeskUserExternalId && vendorExternalId.toString() !== talkdeskUserExternalId) {
              throw new Error(DIFFERENT_EXTERNAL_IDS)
            }

            if (userDefaultIntegration && !this.isDefaultIntegration(userDefaultIntegration, integrationName)) {
              addQueryParam(`default_integration=${userDefaultIntegration}`)
              throw new MismatchedIntegrationError(userDefaultIntegration)
            }

            const mp = this.loadMessageProvider(vendorExternalId, vendorCapabilities)
            this.bindMessageProvider(vendor, mp, vendorCapabilities)
            this.onMessageProviderBinded(vendor, mp, vendorCapabilities)
            this.onMessageProviderReady(vendor, mp, vendorCapabilities)
            this.onAdaptorReady(vendor, mp, vendorCapabilities)
            this.hooks.onReady && this.hooks.onReady(this, mp, vendorExternalId, vendorCapabilities, vendor)
          })
          .catch((error) => {
            this.hooks.onError && this.hooks.onError(error.message)
            const { reportError } = errorReporter(this.sessionInfo.userId)
            switch (error.message) {
              case DIFFERENT_EXTERNAL_IDS:
                reportError(new Error('Cannot login on a agent that is different of the one logged on the CRM'), {
                  integration: this.name
                })
                break
              case NO_EXTERNAL_ID:
                reportError(new Error('Failed to get external id'), {
                  integration: this.name
                })
                break
              case FAILED_TALKDESK_EXTERNAL_ID_FETCH:
                reportError(new Error('Failed to fetch the talkdesk agent external id'), {
                  integration: this.name
                })
                break
              case MISMATCHED_INTEGRATION_ERROR:
                reportError(error, {
                  integration: this.name
                })
                break
            }
          })
      }
    })
  }

  isDefaultIntegration (userDefaultIntegration, integrationName) {
    return (
      userDefaultIntegration === integrationName.toLowerCase() ||
      (userDefaultIntegration === 'salesforce' &&
        integrationName.toLowerCase() === 'lightning')
    )
  }

  isFeatureActive (featureFlag) {
    return this.activeFeatures.indexOf(featureFlag) > -1
  }

  loadMessageProvider (externalId, capabilities) {
    const mp = this.getMessageProvider({
      adaptorName: this.name,
      sessionInfo: this.sessionInfo,
      useSingleSignOut: this.isFeatureActive(CTI_CLIENT_SINGLE_SIGN_OUT),
      useOmnichannel: capabilities.presence,
      externalId
    })

    if (!mp) throw new Error('Failed to load MessageProvider')

    return mp
  }

  bindMessageProvider (vendor, mp, capabilities) {
    mp.onConnect((data) => {
      this.onConnect(data, vendor, mp, capabilities)
    })
    mp.onDisconnect((data) => {
      this.onDisconnect(data, vendor, mp, capabilities)
    })
    mp.onUserLogoutForced(() => {
      this.onUserLogoutForced(vendor, mp, capabilities)
    })
    mp.onOpenContact((data) => {
      this.onOpenContact(data, vendor, mp, capabilities)
    })
    mp.onOpenTicket((data) => {
      this.onOpenTicket(data, vendor, mp, capabilities)
    })
    mp.onHvsCallEnd((data) => {
      this.onHvsCallEnd(data, vendor, mp, capabilities)
    })
    mp.onOpenResource((data) => {
      this.onOpenResource(data, vendor, mp, capabilities)
    })
    mp.onSendData((data) => {
      this.onSendData(data, vendor, mp, capabilities)
    })
    mp.onRequestInteractionExternalId((data) => {
      return this.onRequestInteractionExternalId(data, vendor, mp, capabilities)
    })
    mp.onAgentStatusChanged((data) => {
      this.onAgentStatusChanged(data, vendor, mp, capabilities)
    })
    mp.onUpdateCallbarVisibility((data) => {
      this.onVisibilityUpdate(data, vendor, mp, capabilities)
    })
    mp.onLoginSuccess((data) => {
      this.onLoginSuccess(data, vendor, mp, capabilities)
    })
    mp.onFetchRelateOptions((data) => {
      return this.onFetchRelateOptions(data, vendor, mp, capabilities)
    })
    mp.onRequestRelatedOptionsByChannel((data) => {
      return this.onRequestRelatedOptionsByChannel(data, vendor, mp, capabilities)
    })
  }

  /*
  * Mandatory Hooks
  */
  getExternalId () {
    return Promise.reject(new Error(`Could not get ${this.name} external id`))
  }

  importVendor () {
    return Promise.reject(new Error(`Could not get ${this.name} libray`))
  }

  getVendorCapabilities (vendor) {
    return Promise.resolve({ presence: false })
  }

  /*
  * Optional Hooks
  */

  onVendorReady (vendor, capabilities) {}

  onMessageProviderBinded (vendor, mp, capabilities) {}

  onMessageProviderReady (vendor, mp, capabilities) {}

  onAdaptorReady (vendor, mp, capabilities) {}

  onConnect (data, vendor, mp, capabilities) {}

  onDisconnect (data, vendor, mp, capabilities) {}

  onUserLogoutForced (vendor, mp, capabilities) {}

  onAgentStatusChanged (data, vendor, mp, capabilities) {}

  onOpenContact (userId, vendor, mp, capabilities) {}

  onOpenTicket (data, vendor, capabilities) {}

  onHvsCallEnd (data, vendor, capabilities) {}

  onOpenResource (data, vendor, mp, capabilities) {}

  onSendData (data, vendor, mp, capabilities) {}

  onVisibilityUpdate (data, vendor, mp, capabilities) {}

  onRequestInteractionExternalId (data, vendor, mp, capabilities) {
    return Promise.reject(new Error('Adaptor does not support cases.'))
  }

  onLoginSuccess (data, vendor, mp, capabilities) {}

  onLogin (vendor, mp, capabilities) {}

  onLogout (vendor, mp, capabilities) {}

  onFetchRelateOptions (data, vendor, mp, capabilities) {
    return Promise.reject(new Error('Adaptor does not support relating a voice call to a case or opportunity in Salesforce.'))
  }

  onRequestRelatedOptionsByChannel (data, vendor, mp, capabilities) {
    return Promise.reject(new Error('Adaptor does not support relating a call to a case or opportunity in Salesforce.'))
  }

  applyHeight (height, vendor) {}

  /*
  * Don't know yet =)
  */
  setSoftphoneHeight (height) {}

  showPopover () {}

  hidePopover () {}
}

export default Adaptor
