import parseInput, { getNamespace } from '../input'
import EventEmitter from 'events'

const events = new EventEmitter()

const initialize = (newCaseCallback) => {
  const urlParams = parseUrl()

  if (urlParams.autoPopupEnabled) {
    injectVisualforceIframe(urlParams.salesforceAutoPopupUrl)
    registerNewCaseEventCallback(newCaseCallback)
    handleIframeEvents()
  }
}

const parseUrl = () => {
  const urlParams = parseInput(window.location.search)

  const isInSalesforce = urlParams.integration === 'salesforce'
  const autoPopupEnabled = isInSalesforce
  const salesforceDomain = urlParams.frameOrigin

  const namespace = getNamespace(parseInput(window.location.search).namespace, 'talkdesk', '__')

  const autoPopupVisualForceName = '/apex/' + namespace + 'CasePopupSubscriber'
  const salesforceAutoPopupUrl = salesforceDomain + autoPopupVisualForceName + '?sfdcIFrameOrigin=' + salesforceDomain

  return {
    autoPopupEnabled,
    salesforceAutoPopupUrl
  }
}

const registerNewCaseEventCallback = (newCaseCallback) => {
  events.on('event:case-pop', newCaseCallback)
}

const injectVisualforceIframe = (salesforceAutoPopupUrl) => {
  const iframe = document.createElement('iframe')
  iframe.src = salesforceAutoPopupUrl
  iframe.style = 'display: none'
  document.body.appendChild(iframe)
}

const handleIframeEvents = () => {
  window.addEventListener('message', (event) => {
    const isEventOriginValid = event.origin.includes('force.com')

    if (!isEventOriginValid) {
      return;
    }

    const eventName = 'popupcase'
    const isPopupEvent = typeof event.data === 'string' && event.data.startsWith('popupcase')

    if (isPopupEvent) {
      const caseId = event.data.replace(eventName, '')
      events.emit('event:case-pop', caseId)
    }
  })
}

const API = {
  initialize
}

export default API
